@font-face {
  font-family: "alexbrush";
  src: url("/assets/styles/fonts/alexbrush-regular-webfont.woff2")
      format("woff2"),
    url("/assets/styles/fonts/alexbrush-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.bleuMarine {
  color: #2c748d;
}

.bleuCiel {
  color: #64bbbc;
}

.rose {
  color: #df8bb4;
}

.blanc {
  color: #ffffff;
}

body {
  background-color: rgb(247, 245, 245);
  color: #6a686f !important;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-content {
  flex: 1;
}

#logo {
  margin: 0 auto;
}

.navbar-expand-lg {
  height: 250px !important;
  min-width: 100% !important;
}

#footer {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.foot {
  height: 180px !important;
  width: 100%;
  background-color: #8cb0b0 !important;
  color: #ffffff !important;
  padding: 25px;
  text-align: center;
  margin-bottom: 0;
}

#cadre1 {
  display: none;
}

.cadre1 {
  width: auto;
  padding: 3px;
  background-color: #2c748d;
  border-style: none !important;
}

.cadre2 {
  width: auto;
  padding: 3px;
  background-color: #df8bb4;
  border-style: none !important;
}

.cadre3 {
  width: auto;
  padding: 3px;
  background-color: #64bbbc;
  border-style: none !important;
}

.cadre4 {
  width: auto;
  padding: 3px;
  background-color: #4a4236;
  border-style: none !important;
}

.cadre5 {
  width: auto;
  padding: 3px;
  background-color: #f26b58;
  border-style: none !important;
}

.nom {
  font-family: alexbrush, century, arial !important;
  font-size: 5em;
  color: #ffffff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

#meuble:hover {
  background-color: #8cb0b0 !important;
  border-color: #8cb0b0;
}

#pas_vu:hover {
  background-color: #8cb0b0 !important;
  border-color: #8cb0b0;
}

#toiles:hover {
  background-color: #8cb0b0 !important;
  border-color: #8cb0b0;
}

#encours:hover {
  background-color: #8cb0b0 !important;
  border-color: #8cb0b0;
}

#contact:hover {
  background-color: #8cb0b0 !important;
  border-color: #8cb0b0;
}

#achats:hover {
  background-color: #64bbbc;
  color: white;
}

#inscription:hover {
  background-color: #2c748d;
  color: white;
}

#connexion:hover {
  background-color: #df8bb4;
  color: white;
}

#member:hover {
  background-color: #2c748d;
  color: white;
}

#deconnexion:hover {
  background-color: #df8bb4;
  color: white;
}

#connexion:hover i {
  color: #64bbbc;
}

h1,
h2 {
  color: #6a686f !important;
}

#bloc-images {
  background-color: #f5f5f5;
  width: 100%;
  padding: 30px 0px;
}

#portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
  column-gap: 20px;
}

figure {
  position: relative;
  max-width: 400px;
  max-height: 200px;
}

figcaption {
  position: absolute;
  width: 100%;
  height: 60px;
  margin-top: -60px;
  background-color: rgba(23, 146, 146, 0.5);
  color: white;
  display: none;
  cursor: pointer;
}

.titre-legende {
  padding: 3px;
  line-height: 0.2;
}

canvas {
  max-width: 100%;
}

h1 {
  font-family: alexbrush, century, arial !important;
  font-size: 4.5em !important;
}

.h3,
h3 {
  font-family: alexbrush, century, arial !important;
  font-size: 2em !important;
  color: #ffffff !important;
}

p {
  margin: 0;
  padding: 0;
}

.brun {
  color: #5b3504 !important;
}

.app {
  position: absolute;
}

.navbar {
  background-color: #8cb0b0 !important;
  color: #ffffff !important;
}

.navbar a {
  color: #ffffff !important;
}

.images {
  max-width: 400px;
  max-height: 200px;
  cursor: pointer;
}

.imagesMin {
  max-width: 150px;
  max-height: 75px;
  cursor: pointer;
}

.imagesAgrandies {
  width: 600px;
  height: auto;
}

/* les boutons */
.bouton a {
  color: white !important;
  text-decoration: none !important;
}

#fleche {
  display: none;
}

#footer {
  display: block;
}

/* --  ==== MEDIAQUERIES ====  -- */

@media all and (max-width: 1024px) {
  /* ipad */

  .navbar-expand-lg {
    height: 400px !important;
  }

  .nom {
    text-align: center;
  }
}
@media all and (max-device-width: 480px) {
  /* smartphone */

  .navbar-expand-lg {
    height: 600px !important;
  }

  .nom {
    text-align: center;
  }
}
